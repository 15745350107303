import React from 'react'

function CompanyPage({ pageContext }) {
    console.log(pageContext);
    return (
        <div>
            Hi!
        </div>
    )
}

export default CompanyPage
